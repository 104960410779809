import { IgetTournaments, IregisterTournament } from "../interface/serviceTournaments.inerface"

export const getTournaments = (): Promise<IgetTournaments> => {
    return fetch(`${process.env.REACT_APP_BACK_URL}/tournaments`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    ).then(res => res.json())
}

export const registerTournament = (participant_id: number, tournament_id: string): Promise<IregisterTournament> => {
    return fetch(`${process.env.REACT_APP_BACK_URL}/tournaments_Registrations/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ participant_id, tournament_id, status: 'active', payment: 'false' })
        }
    ).then(res => res.json())
}
