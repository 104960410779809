import { ReactNode, createContext, useState } from 'react';
import { IParticipantContext } from '../interface/participants.interface';
import { IParticipant, SelectOptionType } from '../interface/serviceParticipants.interface';

const ParticipantsContext = createContext<IParticipantContext | null>(null);

const { Provider, Consumer: ParticipantsConsumer } = ParticipantsContext;

const ParticipantsProvider = ({ children }: { children: ReactNode }) => {
  const [id, setId] = useState<number>(0)
  const [email, setEmail] = useState<string>('')
  const [document, setDocument] = useState<string>('')
  const [idFide, setIdFide] = useState<string>('')
  const [fullName, setFullName] = useState<string>('')
  const [dateOfBirth, setDateOfBirth] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [typeDocument, setTypeDocument] = useState<SelectOptionType | null>(null)
  const [typeDocumentOptions, setTypeDocumentOptions] = useState<SelectOptionType[]>([
    { value: 'CC', label: 'Cedula de ciudadania' },
    { value: 'TI', label: 'Tarjeta de identidad' },
    { value: 'RC', label: 'Registro civil' },
    { value: 'CE', label: 'Cedula de extranjería' },
    { value: 'PE', label: 'Pasaporte' },
    { value: 'NIT', label: 'NIT' },
  ])
  const [league, setLeague] = useState<SelectOptionType|  null>(null)
  const [leagueOptions, setLeagueOptions] = useState<SelectOptionType[]>([])
  const [photo, setPhoto] = useState<File | string | null>(null)
  const [participant, setParticipant] = useState<IParticipant | null>(null)

  return (
    <Provider value={{
      id,
      setId,
      email,
      setEmail,
      document,
      setDocument,
      idFide,
      setIdFide,
      fullName,
      setFullName,
      dateOfBirth,
      setDateOfBirth,
      phone,
      setPhone,
      typeDocument,
      setTypeDocument,
      typeDocumentOptions,
      setTypeDocumentOptions,
      league,
      setLeague,
      leagueOptions,
      setLeagueOptions,
      photo,
      setPhoto,
      participant,
      setParticipant
    }} >
      {children}
    </Provider>
  )
}

export { ParticipantsConsumer, ParticipantsProvider, ParticipantsContext }
