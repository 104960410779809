import styled from "styled-components";

export const ContLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    & img {
        z-index: 1;
        width: 250px;
    }
`;

export const Background = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-white);
    opacity: 0.8;
`;
