import styled from 'styled-components'

export const ContInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 4rem);
    margin-top: 20px;
    padding-left: 2rem;
    padding-right: 2rem;
    overflow-y: auto;
`;

export const Info = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
`;

export const ContForm = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 46px);
    flex-direction: column;
    border: 1px solid var(--color-primary);
    border-radius: var(--border-radius-s);
    border-left: 5px solid var(--color-primary);
    padding: 20px;
    margin-bottom: 20px;

    &.columns {
        flex-direction: row;
        flex-wrap: wrap;
    }
    &.align-start {
        align-items: flex-start;
    }
    &.align-end {
        align-items: flex-end;
    }
`
export const Title = styled.h1`
    font-size: var(--font-l);
    color: var(--color-primary);

    @media (max-width: 768px) {
        font-size: var(--font-m);
    }
`;

export const Text = styled.p`
    font-size: var(--font-s);
    color: var(--color-font);
    margin-bottom: 20px;

    &.b {
        color: var(--color-primary);
    }
    &.col-1 {
        width: 100%;
    }
    &.col-2 {
        width: 50%;
    }
    &.col-3 {
        width: 33.33%;
    }
    &.col-4 {
        width: 25%;
    }
    &.col-5 {
        width: 20%;
    }
    &.col-6 {
        width: 16.66%;
    }
    &.col-7 {
        width: 14.28%;
    }
    &.col-8 {
        width: 12.5%;
    }
    &.col-9 {
        width: 11.11%;
    }
    &.col-10 {
        width: 10%;
    }
`;

export const FormGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    margin-bottom: 20px;

    &.vertical {
        flex-direction: column;
        align-items: flex-start;
    }
    &.col-2 {
        width: 50%;
    }
    &.col-3 {
        width: 33.33%;
    }
    &.col-4 {
        width: 25%;
    }
    &.col-5 {
        width: 20%;
    }
    &.col-6 {
        width: 16.66%;
    }
    &.col-7 {
        width: 14.28%;
    }
    &.col-8 {
        width: 12.5%;
    }
    &.col-9 {
        width: 11.11%;
    }
    &.col-10 {
        width: 10%;
    }

    @media (max-width: 768px) {
        width: 100%!important;
    }

    &.xs-vertical {
        @media (max-width: 425px) {
            flex-direction: column;
        }
    }
`;
export const Label = styled.label`
    font-size: var(--font-s);
    margin-bottom: 10px;
    width: 100%;
    text-align: left;
    color: var(--color-primary);
    
    &.space {
        margin-right: 10px;
    }
    &.right {
        text-align: left;
    }
    &.center {
        text-align: center;
    }
    &.left {
        text-align: left;
    }
    &.bold {
        font-weight: bold;
    }
`;

export const Input = styled.input`
    width: calc(100% - 20px);
    border: 1px solid var(--color-primary);
    border-radius: var(--border-radius-s);
    padding: 9px 10px;
    background-color: var(--color-secondary);
    color: var(--color-primary);
    font-size: var(--font-s);
    
    &.space {
        width: calc(100% - 30px);
    }

    &.boxInit {
        border-radius: 10px 0 0 10px;
        @media (max-width: 425px) {
            &.not-box-xs {
                border-radius: var(--border-radius-s);
            }
        }
    }
    &.boxEnd {
        border-radius: 0 10px 10px 0;
    }
    &.notBorderEnd{
        border-right: none;
        @media (max-width: 425px) {
            &.br-xs {
                border: 1px solid var(--color-primary);
            }
        }
    }

    &:focus {
        outline: none;
        border: 2px solid var(--color-primary);
    }

    &.error {
        border: 2px solid var(--color-danger);
    }
    &.col-2 {
        width: 50%;
        @media (max-width: 425px) {
            &.not-col-xs-2 {
                width: calc(100% - 20px);
            }
        }
    }
    &.margin-xs {
        @media (max-width: 425px) {
            margin-bottom: 10px;
        }
    }
    &.box-xs {
        @media (max-width: 425px) {
            border-radius: 0;
        }
    }

`;

export const Select = styled.select`
width: 100%;
border: 1px solid var(--color-primary);
border-radius: var(--border-radius-s);
padding: 10px;
background-color: var(--color-secondary);
color: var(--color-primary);
font-size: var(--font-s);

&.space {
    width: calc(100% - 10px);
}

&.boxInit {
    border-radius: 10px 0 0 10px;
}
&.boxEnd {
    border-radius: 0 10px 10px 0;
}

&:focus {
    outline: none;
    border: 2px solid var(--color-primary);
}

&.error {
    border: 2px solid var(--color-danger);
}
`;

export const Button = styled.button`
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: var(--border-radius-s);
    padding: 9px 10px;
    font-size: var(--font-s);
    cursor: pointer;
    border: 1px solid var(--color-primary);

    &:hover {
        background-color: var(--color-secondary);
        color: var(--color-primary);
    }
    &:disabled {
        background-color: var(--color-background);
        color: var(--color-primary);
        cursor: not-allowed;

        &:hover {
            background-color: var(--color-background);
        }
    }
    &.circle {
        border-radius: var(--border-radius-all);
    }
    &.boxInit {
        border-radius: 10px 0 0 10px;
        @media (max-width: 425px) {
            &.not-box-xs {
                border-radius: var(--border-radius-s);
            }
        }
    }
    &.boxEnd {
        border-radius: 0 10px 10px 0;
        @media (max-width: 425px) {
            &.not-box-xs {
                border-radius: var(--border-radius-s);
            }
        }
    }
    &.box-xs {
        @media (max-width: 425px) {
            border-radius: 0;
        }
    }
    &.icon {
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
            padding-right: 5px;
        }
    }
    &.margin-top {
        margin-top: 20px;
    }
    &.justify {
        width: 100%;
    }
`;

export const ImagePreviw = styled.img`
    width: 100%;
    
    &.col-2 {
        width: 50%;
    }
    &.col-3 {
        width: 33.33%;
    }
    &.col-4 {
        width: 25%;
    }
    &.col-5 {
        width: 20%;
    }
    &.col-6 {
        width: 16.66%;
    }
    &.col-7 {
        width: 14.28%;
    }
    &.col-8 {
        width: 12.5%;
    }
    &.col-9 {
        width: 11.11%;
    }
    &.col-10 {
        width: 10%;
    }
`;

export const Table = styled.table`
    width: calc(100% - 20px);
    margin: 0 10px;
    border-color: var(--color-primary);
    border-radius: var(--border-radius-s);
    border-style: solid;
    border-width: 1px;
    background-color: var(--color-secondary);
    border-collapse: collapse;

    & th {
        color: var(--color-primary);
        border-color: var(--color-primary);
        border-style: solid;
        border-width: 1px;
        padding: 5px 10px;
        text-align: left;
    }

    & td {
        border-color: var(--color-primary);
        border-style: solid;
        border-width: 1px;
        padding: 5px 10px;
    }
`;