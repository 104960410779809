import { Call, Email, Facebook, Instagram, LocationOn } from "@mui/icons-material";
import { Block, BlockSocial, ContBlocksEnd, ContFooter, ContInfo, ContInfoPrimary, ContInfoSecondary, Info, Item, Label, LinkSocial } from "./styled.fotter";

const Fotter = ({ className }: { className?: string}) => {
  return (
    <ContFooter className={className}>
      <ContInfo>
        <ContInfoPrimary>
          <img src="/logo-white.png" alt="logo" />
          <Info>
            <Label>Sobre nosotros</Label>
            <Block>
              <Item>
                <LocationOn /> Tv. 21 Bis #60-35, barrio San Luis. Bogotá,
                Colombia
              </Item>
              <Item>
                <Call /> +57 322 7342 146
              </Item>
              <Item>
                <Email />fecodaz@gmail.com - contacto@fecodaz.org
              </Item>
            </Block>
          </Info>
          <ContBlocksEnd>
            <Info>
              <Label>Legal</Label>
              <Block>
                <Item>Política de privacidad</Item>
                <Item>Términos y Condiciones</Item>
              </Block>
            </Info>
            <Info>
              <Label>Siguenos</Label>
              <BlockSocial>
                <LinkSocial href="https://www.facebook.com/fecodaz/" target="_blank" rel="noreferrer"> <Facebook /> </LinkSocial>
                <LinkSocial href="https://www.instagram.com/fecodaz/" target="_blank" rel="noreferrer"> <Instagram /> </LinkSocial>
              </BlockSocial>
            </Info>
          </ContBlocksEnd>
        </ContInfoPrimary>
        <ContInfoSecondary>
          © 2023. Federación Colombiana de Ajedrez. Todos los derechos
          reservados.
        </ContInfoSecondary>
      </ContInfo>
    </ContFooter>
  );
};

export default Fotter;
