import { ReactNode } from "react";
import { ModalsProvider } from "./modals.context";
import { FlowProvider } from "./flow.context";
import { ParticipantsProvider } from "./participants.context";
import { LoadingProvider } from "./loading.context";

const PrinsipalContext = ({ children }: { children: ReactNode }) => {
  return (
    <LoadingProvider>
      <ParticipantsProvider>
        <FlowProvider>
          <ModalsProvider>{children}</ModalsProvider>
        </FlowProvider>
      </ParticipantsProvider>
    </LoadingProvider>
  );
};

export default PrinsipalContext;
