/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Background from "../../components/background";
import Fotter from "../../components/footer";
import Modals from "../../components/modals";
import Navigation from "../../components/navigation";
import { useParams } from "react-router-dom";
import { FlowContext } from "../../contexts/flow.context";
import { IFlowContext } from "../../interface/flow.interface";
import { Button, Text } from "../../components/styled/styled.recurent";
import { ModalsContext } from "../../contexts/modals.context";
import { IModalsContext } from "../../interface/modals.interface";
import FormSearch from "./formSearch";
import PayTournaments from "./payTournaments";
import Loading from "../../components/loading";
import { LoadingContext } from "../../contexts/loading.context";
import { ILoadingContext } from "../../interface/loading.interface";

const Pays = () => {
  const { loading } = useContext(LoadingContext) as ILoadingContext
  const { flowActive, setFlowActive } = useContext(FlowContext) as IFlowContext
  const { type, viewModal, setViewModal, textInit, textBtn, setTextInit, setTextBtn, setType } = useContext(ModalsContext) as IModalsContext
  const[fotterClass, setFotterClass] = useState<string>('')
  const { pay } = useParams();

  const handleViewModal = () => {
    setViewModal(!viewModal)
  }

  useEffect(() => {
    if (flowActive.name === 'tournament' && flowActive.passed === 0) setFotterClass('')
    if (flowActive.name === 'tournament' && flowActive.passed === 1) setFotterClass('static')
  }, [flowActive])

  useEffect(() => {
    setFlowActive({
      name: 'tournament',
      passed: 0
    })

    if (pay === 'success') {
      setType('white')
      setViewModal(true)
      setTextInit('Felicidades, se ha registrado con exito tu, ya puedes consulta tu licencia en 48 horas')
      setTextBtn('Aceptar')
    }
    if (pay === 'fail') {
      setType('black')
      setViewModal(true)
      setTextInit('Lo sentimos, tu pago no se pudo realizar')
      setTextBtn('Aceptar')
    }
    if (pay === 'load') {
      setType('black')
      setViewModal(true)
      setTextInit('tu pago se esta procesando, intentalo mas tarde')
      setTextBtn('Aceptar')
    }
  }, [])
  return (
    <div>
     {loading && <Loading />}
      <Background />
      <Navigation />
      {flowActive.passed === 0 && <FormSearch />}
      {flowActive.passed === 1 && <PayTournaments />}
      <Modals type={type}>
          <Text>{textInit}</Text>
          <Button onClick={handleViewModal}>{textBtn}</Button>
      </Modals>
      <Fotter className={fotterClass} />
    </div>
  );
};

export default Pays;
