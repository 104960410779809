import { useContext, useState } from "react";
import {
  Button,
  ContForm,
  ContInfo,
  FormGroup,
  Info,
  Input,
  Text,
  Title,
} from "../../components/styled/styled.recurent";
import { SearchParticipants } from "../../services/participants.services";
import { FlowContext } from "../../contexts/flow.context";
import { IFlowContext } from "../../interface/flow.interface";
import { ISearchParticipants } from "../../interface/serviceParticipants.interface";
import { ParticipantsContext } from "../../contexts/participants.context";
import { IParticipantContext } from "../../interface/participants.interface";
import { LoadingContext } from "../../contexts/loading.context";
import { ILoadingContext } from "../../interface/loading.interface";

const FormSearch = () => {
  const { setLoading } = useContext(LoadingContext) as ILoadingContext
  const { setId, setEmail, setDocument: setDocumentParticipant, setIdFide, setFullName, setPhone, setDateOfBirth, setParticipant } = useContext(ParticipantsContext) as IParticipantContext
  const { flowActive, setFlowActive } = useContext(FlowContext) as IFlowContext
  const [document, setDocument] = useState<string>('')

  const Search = (): void => {
    setLoading(true)
    SearchParticipants(document).then((res: ISearchParticipants) => {
      const dateEspiration = new Date(res.participants[0]?.date_expiration || '')
      const dateToday = new Date()
      setLoading(false)
      if (
        res.status === 200 && res.dataUpdate &&
        res.participants[0].payment === 'true' &&
        dateEspiration >= dateToday) {
        setFlowActive({ ...flowActive, passed: 4 })
        setParticipant(res.participants[0])
      }
      if (
        res.status === 200 && res.dataUpdate &&
        res.participants[0].payment === 'true' &&
        dateEspiration < dateToday) {
        setFlowActive({ ...flowActive, passed: 3 })
        setParticipant(res.participants[0])
      }
      if (
        res.status === 200 && res.dataUpdate &&
        res.participants[0].payment === 'false'
      ) {
        setFlowActive({ ...flowActive, passed: 3 })
        setId(res.participants[0].id)
        setParticipant(res.participants[0])
      }
      if (res.status === 200 && !res.dataUpdate) {
        setFlowActive({ ...flowActive, passed: 2 })
        setId(res.participants[0].id)
        setEmail(res.participants[0].email)
        setDocumentParticipant(res.participants[0].document)
        setIdFide(res.participants[0].id_fide)
        setFullName(res.participants[0].full_name)
        setPhone(res.participants[0].phone)
        setDateOfBirth(res.participants[0].date_of_birth)
      }
      if (res.status === 404) {
        setFlowActive({ ...flowActive, passed: 1 })
        setId(0)
      }
    })
  }

  return (
    <ContInfo>
      <Info>
        <ContForm>
          <Title>CARNETIZACIÓN</Title>
          <Text>
            A continuación podrá verificar su existencia en la Base de Datos de
            la Federación Colombiana de Ajedrez y descargar su correspondiente
            carnet. Por favor digite su <b>ID FIDE</b> o número de{" "}
            <b>DOCUMENTO</b> sin espacios ni puntos
          </Text>
          <FormGroup>
            <Input className="boxInit" type="text" placeholder="ID FIDE" value={document} onChange={(e) => setDocument(e.target.value)} />
            <Button className="boxEnd" onClick={Search} disabled={document === ''}>Buscar</Button>
          </FormGroup>
        </ContForm>
      </Info>
    </ContInfo>
  );
};

export default FormSearch;
