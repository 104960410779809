import { BackgroundImg } from "./style.backgrond"

const Background = () => {
    return (
        <BackgroundImg>
            <div />
        </BackgroundImg>
    )
}

export default Background