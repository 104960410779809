import { RouterProvider } from 'react-router-dom';
import { router } from './routings';
import PrinsipalContext from './contexts';

function App() {
  return (
    <PrinsipalContext>
      <RouterProvider router={router} />
    </PrinsipalContext>
  );
}

export default App;
