import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  width: calc(100% - 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
  font-size: var(--font-xs);

  @media (max-width: 1024px) {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-primary);
  }
`;

export const ContMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @media (max-width: 460px) {
    flex-direction: column;
  }
`;

export const Logo = styled.a`
  display: flex;
  align-items: center;
  color: var(--color-black);
  cursor: pointer;
  text-decoration: none;
  & h1 {
      font-size: var(--font-l);
      margin-left: 10px;
  }

  @media (max-width: 1024px) {
    order: 1;
  }
`;

export const MenuCentral = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;

  @media (max-width: 1024px) {
    order: 2;
  }

  @media (max-width: 570px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0px;
  }
`;

export const ItemMenu = styled.a`
  display: flex;
  align-items: center;
  font-size: var(--font-xs);
  color: var(--color-font);
  cursor: pointer;
  padding: 5px 10px;
  border-radius: var(--border-radius-m);
  text-decoration: none;
  &:hover {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
  &.active {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
`;
export const ItemMenuLocal = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--font-xs);
  color: var(--color-font);
  cursor: pointer;
  padding: 5px 10px;
  border-radius: var(--border-radius-m);
  &:hover {
    background-color: var(--color-secondary);
    & a {
        color: var(--color-primary);
    }
  }
  &.active {
    background-color: var(--color-secondary);
    & a {
        color: var(--color-primary);
    }
  }
  & a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--color-font);
  }
`;

export const ButtonMenu = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--font-xs);
  color: var(--color-font);
  cursor: pointer;
  padding: 5px 10px;
  border-radius: var(--border-radius-s);
  text-decoration: none;
  &:hover {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
  &.active {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
`;

export const ContDropDown = styled.div`
  display: flex;
  position: absolute;
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--color-white);
  border-radius: var(--border-radius-m);
  padding: 5px;
  box-shadow: var(--shadow);
  z-index: 1;
  top: 80px;
`;

export const OptionsDropDown = styled.a`
  display: flex;
  align-items: center;
  font-size: var(--font-xs);
  color: var(--color-primary);
  cursor: pointer;
  padding: 5px 10px;
  border-radius: var(--border-radius-m);
  text-decoration: none;
  &:hover {
    background-color: var(--color-secondary);
  }
  & svg {
    padding-right: 5px;
  }
`;

export const ContSocial = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 570px) {
    margin-left: 10px;
  }
`;


export const SocialIcon = styled.a`
  display: flex;
  color: var(--color-primary);
  cursor: pointer;
  text-decoration: none;
  width: 24px;
`;

export const MenuRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1024px) {
    order: 1;
  }
  @media (max-width: 460px) {
    justify-content: flex-start;
  }
`;

export const ButtonPay = styled.button`
  display: flex;
  align-items: center;
  font-size: var(--font-xs);
  color: var(--color-primary);
  cursor: pointer;
  padding: 5px 10px;
  border-radius: var(--border-radius-s);
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-primary);
  margin-right: 10px;
  &:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
    & a {
      color: var(--color-white);
    }
  }
  & a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--color-primary);
  }
`;

export const ButtonLogin = styled.a`
  display: flex;
  align-items: center;
  font-size: var(--font-xs);
  color: var(--color-white);
  background-color: var(--color-primary);
  cursor: pointer;
  padding: 5px 10px;
  border-radius: var(--border-radius-s);
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-primary);
  &:hover {
    background-color: var(--color-info);
    border-color: var(--color-info);
  }
`;
