import { ILoadLeagues, IRequestSaveParticipants, ISaveParticipant, ISearchEmailParticipants, ISearchParticipants } from "../interface/serviceParticipants.interface"

export const SearchParticipants = (document: string): Promise<ISearchParticipants> => {
    return fetch(`${process.env.REACT_APP_BACK_URL}/participants/search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ document })
    }).then((res) => res.json())
}

export const SearchEmailParticipants = (email: string): Promise<ISearchEmailParticipants> => {
    return fetch(`${process.env.REACT_APP_BACK_URL}/participants/search/email`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
    }).then((res) => res.json())
}

export const LoadLeagues = (): Promise<ILoadLeagues> => {
    return fetch(`${process.env.REACT_APP_BACK_URL}/league`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then((res) => res.json())
}

export const SaveParticipant = function (participant: IRequestSaveParticipants, passed: number, id: number ): Promise<ISaveParticipant> {
    // Fecha defecha actual mas 1 año
    const date = new Date();
    const date_expiration = `${date.getFullYear()+1}-${date.getMonth()+1}-${date.getDate()}`;
    const request = { ...participant, status: 'active', payment: 'false', date_expiration: date_expiration };

    return id === 0
        ? fetch(`${process.env.REACT_APP_BACK_URL}/participants`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then((res) => res.json())
        : fetch(`${process.env.REACT_APP_BACK_URL}/participants/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then((res) => res.json())
}

export const SaveParticipantActive = function (participant: IRequestSaveParticipants, passed: number, id: number ): Promise<ISaveParticipant> {
    // Fecha defecha actual mas 1 año
    const date = new Date();
    const date_expiration = `${date.getFullYear()+1}-${date.getMonth()+1}-${date.getDate()}`;
    const request = { ...participant, status: 'active', payment: 'true', date_expiration: date_expiration };

    return id === 0
        ? fetch(`${process.env.REACT_APP_BACK_URL}/participants`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then((res) => res.json())
        : fetch(`${process.env.REACT_APP_BACK_URL}/participants/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then((res) => res.json())
}
