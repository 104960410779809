import styled from 'styled-components';

export const ContModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: var(--color-white-modal);
`;

export const Modal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 400px;
    height: 200px;
    background-color: var(--color-secondary);
    border-radius: var(--border-radius-m);
    border: 1px solid var(--color-primary);
    box-shadow: 0 40px 0 20px var(--color-primary);
`;

export const ContImg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;

    & img {
        position: relative;
        bottom: 120px;
        height: 300px;
    }

`;

export const ContBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    z-index: 2;
`;