import { ReactNode, createContext, useState } from 'react'
import { IFlow, IFlowContext } from '../interface/flow.interface'

const FlowContext = createContext<IFlowContext | null >(null);
const { Provider, Consumer: FlowConsumer } = FlowContext;

const FlowProvider = ({ children }: { children: ReactNode}) => {
    const [flows, setFlows] = useState<Array<IFlow>>([{
        name: 'participants',
        passed: 0
    }, {
        name: 'Tournament',
        passed: 0
    }])
    const [flowActive, setFlowActive] = useState<IFlow>({ 
        name: 'participants',
        passed: 0
    })
    
    return(
        <Provider value={{
            flows,
            setFlows,
            flowActive,
            setFlowActive
        }}>
            {children}
        </Provider>
    )
}

export { FlowContext, FlowProvider, FlowConsumer }
