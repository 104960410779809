import { useState } from "react";
import {
  Home,
  CalendarMonth,
  LibraryBooks,
  Groups,
  Newspaper,
  KeyboardArrowDown,
  KeyboardArrowUp,
  AutoStories,
  Login,
  Badge,
  Facebook,
  Instagram,
  EmojiEvents,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

import {
  ButtonLogin,
  ButtonMenu,
  ButtonPay,
  ContDropDown,
  ContMenu,
  ContSocial,
  DropDown,
  ItemMenu,
  ItemMenuLocal,
  Logo,
  MenuCentral,
  MenuRight,
  Nav,
  OptionsDropDown,
  SocialIcon,
} from "./styled.navigation";

const Navigation = () => {
  const [ViewDropDown, setViewDropDown] = useState<boolean>(false);

  return (
    <Nav>
      <ContMenu>
        <Logo href={`${process.env.REACT_APP_OLD_URL}/`}>
          <img src="/logo.png" alt="logo" />
          <h1>FECODAZ</h1>
        </Logo>
        <MenuCentral>
          <ItemMenu href={`${process.env.REACT_APP_OLD_URL}/`}>
            <Home />
            Inicio
          </ItemMenu>
          <ItemMenu href={`${process.env.REACT_APP_OLD_URL}/calendar`}>
            <CalendarMonth />
            Calendario
          </ItemMenu>
          <ItemMenu href={`${process.env.REACT_APP_OLD_URL}/resolutions`}>
            <LibraryBooks />
            Resoluciones
          </ItemMenu>
          <ItemMenuLocal>
            <Link to="/carding">
              <Badge />
              Carnnetización
            </Link>
          </ItemMenuLocal>
          <ButtonMenu onClick={() => setViewDropDown(!ViewDropDown)}>
            Páginas
            {!ViewDropDown ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            <ContDropDown style={{ display: ViewDropDown ? "flex" : "none" }}>
              <DropDown>
                <OptionsDropDown
                  href={`${process.env.REACT_APP_OLD_URL}/about`}
                >
                  <Groups />
                  Acerca de Nosotros
                </OptionsDropDown>
                <OptionsDropDown href={`${process.env.REACT_APP_OLD_URL}/news`}>
                  <Newspaper />
                  Noticias
                </OptionsDropDown>
                <OptionsDropDown
                  href={`${process.env.REACT_APP_OLD_URL}/magazine`}
                >
                  <AutoStories />
                  Revista
                </OptionsDropDown>
              </DropDown>
            </ContDropDown>
          </ButtonMenu>
          <ContSocial>
            <SocialIcon href="https://www.facebook.com/Fecodazajedrez">
              <Facebook />
            </SocialIcon>
            <SocialIcon href="https://www.instagram.com/fecodazajedrez">
              <Instagram />
            </SocialIcon>
          </ContSocial>
        </MenuCentral>
        <MenuRight>
          <ButtonPay>
            <Link to="/pays">
              <EmojiEvents />
              Torneos
            </Link>
          </ButtonPay>
          <ButtonLogin href={`${process.env.REACT_APP_OLD_URL}/login`}>
            <Login />
            Ingreso
          </ButtonLogin>
        </MenuRight>
      </ContMenu>
    </Nav>
  );
};

export default Navigation;
