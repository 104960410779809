import { ReactNode, useContext, useEffect, useState } from "react"
import { ContBody, ContImg, ContModal, Modal } from "./styled.modals"
import { ModalsContext } from "../../contexts/modals.context"
import { IModalsContext } from "../../interface/modals.interface"

const Modals = ({ children, type } : { children: ReactNode, type: string }) => {
    const { viewModal } = useContext(ModalsContext) as IModalsContext
    const [Type, setType] = useState<string>('black')

    useEffect(() => {
      setType(type)
    }, [type])
    

    return (
        <ContModal style={{ display: viewModal ? 'flex' : 'none' }}>
            <Modal>
            <ContImg>
                {Type === 'black' && <img src="/assets/56.png" alt="ficha de ajedrez" />}
                {Type === 'white' && <img src="/assets/57.png" alt="ficha de ajedrez" />}
            </ContImg>
            <ContBody>
                {children}
            </ContBody>
            </ Modal>
        </ContModal>
    )
}

export default Modals
