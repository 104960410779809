import {
    createBrowserRouter,
} from 'react-router-dom';
import Home from '../pages/home';
import Carding from '../pages/carding';
import Pays from '../pages/pays';
import PayCarnet from '../pages/payCarnet';
import LoadData from '../pages/loadData';

const router = createBrowserRouter([
    { path: "/", element: <Home /> },
    { path: "/carding", element: <Carding /> },
    { path: "/carding/:pay", element: <Carding /> },
    { path: "/pays", element: <Pays /> },
    { path: "/pays/carnet/:document", element: <PayCarnet /> },
    { path: "/load-data", element: <LoadData /> },
])

export { router }
