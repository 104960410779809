import {
  ContForm,
  ContInfo,
  Info,
  Title,
  Text,
  FormGroup,
  Label,
  ImagePreviw,
} from "../../components/styled/styled.recurent";

const PayTournaments = () => {
  return (
    <ContInfo>
      <Info>
        <ContForm className="columns align-start">
          <FormGroup className="vertical">
            <Title>Pago de inscripción de torneo</Title>
            <Text>
              Eneste espacio se podra realizar el pago de la inscripción al torneo que desee. puede ser
              por transferencia o por PayU.
            </Text>
          </FormGroup>
          {/* <FormGroup className="vertical col-2">
            <Label className="bold">paga por PayU</Label>
            <a href="https://biz.payulatam.com/B0f5e8100C55145">
              <img
                src="https://ecommerce.payulatam.com/img-secure-2015/boton_pagar_grande.png"
                alt="pago pay u"
              />
            </a>
          </FormGroup> */}
          <FormGroup className="vertical col-2">
            <Label className="bold" >paga por Transferencia Bancolombia</Label>
            <Text>Cuando generes pago por QR , por favor envía un correo informando del concepto pagado con los siguientes datos: Nombre Completo, ID FIDE o tipo y número  de documento, Liga y . copia de pago</Text>
          </FormGroup>
          <FormGroup className="vertical col-2">
            {/* <Label className="bold" >paga por Transferencia Bancolombia</Label> */}
            <ImagePreviw src="/assets/qr_bancolombia.png" alt="QR de pago" />
          </FormGroup>
        </ContForm>
      </Info>
    </ContInfo>
  );
};

export default PayTournaments;
