import { useEffect } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_OLD_URL}`
  },[])
  return (
    <>
      <div>home</div>
      <ul>
        <li>
          <Link to="/carding">carding</Link>
        </li>
        <li>
          <Link to="/pays">pays</Link>
        </li>
        <li>
          <Link to="/load-data">load-data</Link>
        </li>
      </ul>
    </>
  );
};

export default Home;
