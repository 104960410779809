import styled from 'styled-components';

export const ContFooter = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 2rem);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 25px;
    padding-bottom: 20px;
    font-size: var(--font-xs);
    background-color: var(--color-black);
    color: var(--color-white);
    position: absolute;
    bottom: 0;
    border-radius: 50px 50px 0 0;

    &.static {
        position: initial;
    }

    @media (max-height: 630px) {
        position: initial;
    }

    @media (max-width: 768px) {
        position: initial;
    }
`;

export const ContInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
`;

export const ContInfoPrimary = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--color-primary);
    padding-bottom: 20px;

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
`;

export const ContInfoSecondary = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-top: 20px;
`;

export const Info = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-xs);
    margin-bottom: 20px;

    @media (max-width: 425px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const Label = styled.label`
    font-size: var(--font-s);
    padding-right: 20px;
    min-width: 85px;
    text-align: right;

    @media (max-width: 425px) {
        align-self: center;
        margin-bottom: 10px;
    }
`;

export const Block = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: var(--font-xs);
    border-left: 2px solid var(--color-white);
    flex-direction: column;
    padding-left: 20px;
    
`;

export const Item = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 20px;
    flex-direction: row;
`;

export const ContBlocksEnd = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid var(--color-primary);
        padding-top: 10px;
    }

    @media (max-width: 425px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const BlockSocial = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-left: 2px solid var(--color-white);
    padding-left: 20px;
`;

export const LinkSocial = styled.a`
    color: var(--color-white);
    text-decoration: none;
    padding-right: 10px;
`;
