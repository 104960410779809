import { ReactNode, createContext, useState } from "react";
import { ILoadingContext } from "../interface/loading.interface";

const LoadingContext = createContext<ILoadingContext | null>(null);
const { Provider, Consumer: LoadingConsumer } = LoadingContext;

const LoadingProvider = ({ children }: { children: ReactNode}) => {
    const [loading, setLoading] = useState<boolean>(false);

    return(
        <Provider value={{
            loading,
            setLoading
        }}
        >
            {children}
        </Provider>
    )
}

export { LoadingContext, LoadingProvider, LoadingConsumer }
