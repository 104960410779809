import { useState } from "react"
import * as XLSX from "xlsx"
import { ExcelData, ILoadData } from "../../interface/load-data.interface"
import { SaveParticipantActive, SearchParticipants } from "../../services/participants.services";
import { ISearchParticipants } from "../../interface/serviceParticipants.interface";

const LoadData = () => {
    const [excelData, setExcelData] = useState<ExcelData | null>(null);
    const [loadingSave, setloadingSave] = useState(false)

  // Función para manejar la carga del archivo Excel
  const handleExcelUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target?.result;
      if (!data) return;

      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Suponiendo que queremos el primer sheet

      const sheet = workbook.Sheets[sheetName];
      const excelRows = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false, defval: '' });
      const headers = excelRows[0] as string[];
      const rows = excelRows.slice(1);

      console.log(headers, rows);

      setExcelData({ headers, rows });
    };

    reader.readAsBinaryString(file);
  };

  // Función para enviar los datos al servidor
  const handleSaveData = () => {
    console.log('go data');
    if (!excelData) return;
    const data: ILoadData[] = excelData.rows.map((row) => {
      return {
        id: row[0],
        email: row[1] !== "" ? row[1] : null,
        name: row[2],
        phone: row[3] !== "" ? row[3] : null,
        dateBirth: row[4] !== "1/0/00" ? new Date(row[4]) : new Date(),
        typeDocument: row[5] !== 0 ? row[5] : null,
        document: row[6] !== "0    " ? row[6] : null,
        idFide: row[7] !== "0" ? row[7] : null,
        league: row[8] !== 0 ? row[8] : null,
        dateExpiration: row[10] !== "1/0/00" ? new Date(row[10]) : new Date(),
        status: row[11],
        payment: JSON.parse(row[12]),
      };
    });
    data.forEach((item) => {
        item.document = item.document ? parseFloat(item.document) : null
        item.idFide = item.idFide ? parseFloat(item.idFide) : null
        console.log('item ->', item);
        const search = item.idFide || item.document || 0;
        console.log('search ->', search);
        setloadingSave(true)
        search !== 0 && SearchParticipants(search.toString()).then((res: ISearchParticipants) => {
            console.log('res search ->', res);
            const id = res.status === 200 ? res.participants[0].id : 0;
            SaveParticipantActive({
                email: item.email,
                document: item.document,
                full_name: item.name,
                id_fide: item.idFide,
                league: item.league,
                type_document: item.typeDocument,
                date_of_birth: `${item.dateBirth.getFullYear()}-${item.dateBirth.getMonth() + 1}-${item.dateBirth.getDate()}`,
                phone: item.phone,
                photo: null,
                payment: item.payment,
                date_expiration: `${item.dateExpiration.getFullYear() + 1}-${item.dateExpiration.getMonth() + 1}-${item.dateExpiration.getDate()}`,
            }, 0, id).then((res) => {
                console.log('res save ->', res);
                setloadingSave(false);
            })
        })
    });
  };

  // Función para renderizar la tabla con los datos del archivo Excel
  const renderExcelData = () => {
    if (!excelData) return null;

    return (
      <div>
        <h2>Excel Data Preview</h2>
        <table>
          <thead>
            <tr>
              {excelData.headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {excelData.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Array.isArray(row) && row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        <button onClick={handleSaveData}>Enviar</button>
        <button onClick={() => setExcelData(null)}>Reset</button>
      </div>
    );
  };

  return (
    <div>
      <input type="file" accept=".xlsx, .xls" onChange={handleExcelUpload} />
      {renderExcelData()}
      {loadingSave && <p>Loading...</p>}
    </div>
  );
}

export default LoadData