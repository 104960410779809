import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { SaveParticipantActive, SearchParticipants } from "../../services/participants.services"
import { IParticipant, ISearchParticipants } from "../../interface/serviceParticipants.interface"

const PayCarnet = () => {
    const [pay, setpay] = useState<boolean>(false)
    const [participant, setParticipant] = useState<IParticipant>()
    const { document } = useParams()

    useEffect(() => {
        setpay(false)
    document && SearchParticipants(document).then((res: ISearchParticipants) => {
        SaveParticipantActive({
            document: res.participants[0].document,
            email: res.participants[0].email,
            full_name: res.participants[0].full_name,
            id_fide: res.participants[0].id_fide,
            league: res.participants[0].league,
            type_document: res.participants[0].type_document,
            date_of_birth: res.participants[0].date_of_birth,
            phone: res.participants[0].phone,
            photo: null,
            payment: 'true'
        }, 4, res.participants[0].id).then((res) => {
            setpay(true)
            setParticipant(res.participants)
        })
    })

    }, [document])
    return (!pay ? <div>Cargando...</div>
    : <div>
        A {participant?.full_name} con el documento {participant?.document} se le cargo el pago de la licencia.
    </div>)
}

export default PayCarnet