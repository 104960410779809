import {
  ContForm,
  ContInfo,
  Info,
  Title,
  Text,
  FormGroup,
  Label,
  ImagePreviw,
  Table,
} from "../../components/styled/styled.recurent";

const PayParticipants = () => {
  return (
    <ContInfo>
      <Info>
        <ContForm className="columns align-start">
          <FormGroup className="vertical">
            <Title>Pago de licencia</Title>
            <Text>
            Si usted llegó hasta acá, es porque necesita hacer el pago de su licencia,
            pero si ya lo hizo, por favor comunicarse con la Federación al correo contacto@fecodaz.org.
            En este espacio se podrá realizar el pago de la licencia anual por transferencia.
            </Text>
          </FormGroup>
          {/* <FormGroup className="vertical col-2">
            <Label className="bold">paga por PayU</Label>
            <a href="https://biz.payulatam.com/B0f5e8100C55145">
              <img
                src="https://ecommerce.payulatam.com/img-secure-2015/boton_pagar_grande.png"
                alt="pago pay u"
              />
            </a>
          </FormGroup> */}
          <FormGroup className="vertical col-2">
            <Label className="bold" >Pago por transferencia Bancolombia o Nequi.</Label>
            <Text>Cuando generes pago por QR , por favor envía un correo informando del concepto 
              pagado con los siguientes datos:
              Nombre Completo, ID FIDE, tipo y número de documento, Liga, y soporte de pago</Text>
            <Label className="bold">Tabla de cosos</Label>
            <Table>
              <tbody>
                <tr>
                  <th>Edad</th>
                  <th>Pago</th>
                </tr>
                <tr>
                  <td>Menores de 8 años</td>
                  <td>Gratis</td>
                </tr>
                <tr>
                  <td>8 a 11 años</td>
                  <td>$20.000</td>
                </tr>
                <tr>
                  <td>12 a 17 años</td>
                  <td>$30.000</td>
                </tr>
                <tr>
                  <td>18 a 64 años</td>
                  <td>$45.000</td>
                </tr>
              </tbody>
            </Table>
          </FormGroup>
          <FormGroup className="vertical col-2">
            {/* <Label className="bold" >paga por Transferencia Bancolombia</Label> */}
            <ImagePreviw src="/assets/qr_bancolombia.png" alt="QR de pago" />
          </FormGroup>
        </ContForm>
      </Info>
    </ContInfo>
  );
};

export default PayParticipants;
