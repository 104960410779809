/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { 
  // ChangeEvent, 
  useContext, useEffect, useState } from "react";
import Select, { SingleValue, ActionMeta, Theme } from "react-select";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  Button,
  ContForm,
  ContInfo,
  FormGroup,
  // ImagePreviw,
  Info,
  Input,
  Label,
  Text,
} from "../../components/styled/styled.recurent";
// import { VisuallyHiddenInput } from "../../components/styled/styledMui.recurent";
import { FlowContext } from "../../contexts/flow.context";
import { IFlowContext } from "../../interface/flow.interface";
import {
  LoadLeagues,
  SaveParticipant,
  SearchEmailParticipants,
} from "../../services/participants.services";
import {
  ILeague,
  ILoadLeagues,
  ISearchEmailParticipants,
  SelectOptionType,
} from "../../interface/serviceParticipants.interface";
import { ParticipantsContext } from "../../contexts/participants.context";
import { IParticipantContext } from "../../interface/participants.interface";
import { IModalsContext } from "../../interface/modals.interface";
import { ModalsContext } from "../../contexts/modals.context";
import { LoadingContext } from "../../contexts/loading.context";
import { ILoadingContext } from "../../interface/loading.interface";

const FormParticipants = () => {
  const { setLoading } = useContext(LoadingContext) as ILoadingContext
  const {
    id,
    setId,
    document,
    setDocument,
    idFide,
    setIdFide,
    fullName,
    setFullName,
    dateOfBirth,
    setDateOfBirth,
    phone,
    setPhone,
    typeDocument,
    setTypeDocument,
    typeDocumentOptions,
    league,
    setLeague,
    leagueOptions,
    setLeagueOptions,
    email,
    setEmail,
    // photo,
    setPhoto,
    setParticipant
  } = useContext(ParticipantsContext) as IParticipantContext;
  const { flowActive, setFlowActive } = useContext(FlowContext) as IFlowContext;
  const { setViewModal, setTextInit: setTextInitModal, setTextBtn: setTextBtnModal, setType } = useContext(ModalsContext) as IModalsContext
  const [textInit, setTextInit] = useState<string>("Carnetización");
  const [textBtn, setTextBtn] = useState<string>("Registrase");

  const searchToEmail = (): void => {
    setLoading(true)
    SearchEmailParticipants(email).then((res: ISearchEmailParticipants) => {
      setLoading(false)
      if (res.status === 200) {
        setId(res.participants.id);
        setDocument(res.participants.document);
        setFullName(res.participants.full_name);
        setDateOfBirth(res.participants.date_of_birth);
        setPhone(res.participants.phone);
        const loadTypeDocument: SelectOptionType | undefined = typeDocumentOptions.find(
          (option) => option.value === res.participants.type_document
        )
        loadTypeDocument && setTypeDocument(loadTypeDocument);
        const loadLeague: SelectOptionType | undefined = leagueOptions.find(
          (option) => option.value === res.participants.league
        );
        loadLeague && setLeague(loadLeague);
        setFlowActive({ ...flowActive, passed: 2 });
        setPhoto(res.participants.photo);
      }
      if (res.status === 404) {
        setDocument("");
        setFullName("");
        setDateOfBirth("");
        setPhone("");
        setTypeDocument(null);
        setLeague(null);
        setPhoto(null);
      }
    });
  };

  const LoadLeaguesOptions = (): void => {
    setLoading(true)
    LoadLeagues().then((res: ILoadLeagues) => {
      setLoading(false)
      setLeagueOptions(
        res.data.map(
          (league: ILeague): SelectOptionType => ({
            value: league.id.toString(),
            label: league.name,
          })
        )
      );
    });
  };

  const changeLeague = (
    value: SingleValue<SelectOptionType>,
    actionMeta: ActionMeta<SelectOptionType>
  ): void => {
    setLeague(value || null);
  };
  
  const changeTypeDocument = (
    value: SingleValue<SelectOptionType>,
    actionMeta: ActionMeta<SelectOptionType>
  ): void => {
    setTypeDocument(value || null);
  };

  // const changePhoto = (e: ChangeEvent<HTMLInputElement>): void => {
  //   if (e.target.files) {
  //     setPhoto(e.target.files[0]);
  //   }
  // }

  const handleNext = (): void => {
    if (email !== "" && email !== undefined && 
    document !== "" && document !== undefined &&
    fullName !== "" && fullName !== undefined &&
    dateOfBirth !== "" && dateOfBirth !== undefined &&
    phone !== "" && phone !== undefined && 
    typeDocument !== null && league !== null) {   
      setLoading(true)
      SaveParticipant({
        email,
        document,
        id_fide: idFide,
        full_name: fullName,
        date_of_birth: dateOfBirth,
        phone,
        type_document: typeDocument?.value || '',
        league: league?.value || '',
        // photo: typeof photo === 'object' ? photo : null,
        photo: null,
      }, flowActive.passed, id).then((res) => {
        setLoading(false)
        setParticipant(res.participants);
        setFlowActive({ ...flowActive, passed: 3 });
      })
    } else {
      setType('blank')
      setTextInitModal('todos los campos con * son obligatorios')
      setTextBtnModal('Entendido')
      setViewModal(true)
    }
  }

  useEffect(() => {
    if (flowActive.passed === 1)
      setTextInit("Registrate para obtener tu carnet");
    if (flowActive.passed === 2)
      setTextInit("Actualiza tus datos para obtener tu carnet");
    if (flowActive.passed === 1) setTextBtn("Registrarse");
    if (flowActive.passed === 2) setTextBtn("Actualizar");
  }, [flowActive]);

  useEffect(() => {
    LoadLeaguesOptions();
  }, []);

  return (
    <ContInfo>
      <Info>
        <ContForm className="columns">
          <Text className="col-1">{textInit}</Text>
          <FormGroup className="vertical col-2">
            <Label className="bold">Email *</Label>
            <Input
              className="space"
              onBlur={searchToEmail}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup className="vertical col-2">
            <Label className="bold">Nombre Completo *</Label>
            <Input
              className="space"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </FormGroup>
          <FormGroup className="vertical col-2">
            <Label className="bold">Telefono *</Label>
            <Input
              className="space"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormGroup>
          <FormGroup className="vertical col-2">
            <Label className="bold">Fecha de Nacimiento *</Label>
            <Input
              className="space"
              type="date"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
          </FormGroup>
          <FormGroup className="vertical col-2">
            <Label className="bold">Tipo de Documento *</Label>
            <Select
              className="space-select"
              value={typeDocument}
              theme={(theme: Theme): Theme => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary25: "var(--color-primary)",
                  primary: "var(--color-secondary)",
                  neutral0: "var(--color-secondary)",
                  neutral20: "var(--color-primary)",
                  neutral30: "var(--color-primary)",
                  neutral40: "var(--color-primary)",
                },
              })}
              onChange={changeTypeDocument}
              options={typeDocumentOptions}
            />
          </FormGroup>
          <FormGroup className="vertical col-2">
            <Label className="bold">Documento *</Label>
            <Input
              className="space"
              value={document}
              onChange={(e) => setDocument(e.target.value)}
            />
          </FormGroup>
          <FormGroup className="vertical col-2">
            <Label className="bold">Liga *</Label>
            <Select
              className="space-select"
              value={league}
              theme={(theme: Theme): Theme => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary25: "var(--color-primary)",
                  primary: "var(--color-secondary)",
                  neutral0: "var(--color-secondary)",
                  neutral20: "var(--color-primary)",
                  neutral30: "var(--color-primary)",
                  neutral40: "var(--color-primary)",
                },
              })}
              onChange={changeLeague}
              options={leagueOptions}
            />
          </FormGroup>
          <FormGroup className="vertical col-2">
            <Label className="bold">Id fide (Opcional)</Label>
            <Input
              className="space"
              value={idFide}
              onChange={(e) => setIdFide(e.target.value)}
            />
          </FormGroup>
           {/* <FormGroup className="vertical col-2">
            {photo && <ImagePreviw className="col-5" src={typeof photo === "string" ? photo : URL.createObjectURL(photo)} alt="photo" />}
            <Button className="icon margin-top justify">
              <VisuallyHiddenInput type="file" onChange={changePhoto} />
              <CloudUploadIcon />
              cargar tu foto
            </Button>
          </FormGroup> */}
          <FormGroup>
            <Button onClick={handleNext}>{textBtn}</Button>
          </FormGroup>
        </ContForm>
      </Info>
    </ContInfo>
  );
};

export default FormParticipants;
