/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import {
  ContForm,
  ContInfo,
  Info,
  Title,
  FormGroup,
} from "../../components/styled/styled.recurent";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { ParticipantsContext } from "../../contexts/participants.context";
import { IParticipantContext } from "../../interface/participants.interface";
import { LoadLeagues } from "../../services/participants.services";
import {
  ILeague,
  ILoadLeagues,
  SelectOptionType,
} from "../../interface/serviceParticipants.interface";

const PdfParticipants = () => {
  const { participant, setLeagueOptions, leagueOptions } = useContext(
    ParticipantsContext
  ) as IParticipantContext;

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
    sectionImg: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      width: "90%",
      color: "#000",
    },
    sectionImgBg: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
    },
    section: {
      color: "#000",
      marginTop: 55,
      marginLeft: 10,
      marginRight: 10,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      fontSize: 14,
    },
    textTitle: {
      fontSize: 9.5,
      fontWeight: "bold",
      marginBottom: 10,
      textAlign: "center",
    },
    textSudTitle: {
      fontSize: 25,
      fontWeight: "bold",
      marginBottom: 30,
    },
    textOne: {
      fontSize: 16.5,
      marginBottom: 15,
      width: "86%",
      textAlign: "center",
    },
    textTwo: {
      fontSize: 10,
      marginBottom: 5,
      borderTopWidth: 1,
      borderTopStyle: "solid",
      borderTopColor: "#000",
      position: "relative",
      bottom: 35,
      paddingTop: 5,
    },
    textThree: {
      fontSize: 8,
      marginBottom: 5,
      position: "relative",
      bottom: 35,
      paddingTop: 5,
    }
  });

  const LoadLeaguesOptions = (): void => {
    LoadLeagues().then((res: ILoadLeagues) => {
      setLeagueOptions(
        res.data.map(
          (league: ILeague): SelectOptionType => ({
            value: league.id.toString(),
            label: league.name,
          })
        )
      );
    });
  };

  // funcion que pone la primera letra en mayuscula y el resto en minuscula
  const toTitleCase = (str: string): string => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // funcion que encuentra la liga segun el id
  const findLeagueById = (id: string): string => {
    return leagueOptions.find((league: SelectOptionType) => league.value === id)?.label || '';
  }

  // funcion de formato de fecha
  const formatDate = (date: string): string => {
    const d = new Date(date);
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()-1}, hasta ${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  }

  useEffect(() => {
    LoadLeaguesOptions();
  }, []);

  return (
    <ContInfo>
      <Info>
        <ContForm className="columns align-start">
          <FormGroup className="vertical">
            <Title>Pdf de licencia</Title>
          </FormGroup>
          <PDFViewer style={{ width: "90vw", height: "80vh" }}>
            <Document>
              <Page size="A5" orientation="landscape" style={styles.page}>
                <View style={styles.sectionImgBg}>
                  <Image style={{ width: "100%", height: "100%" }} src="/assets/bg_carnet.png" />
                </View>
                <View style={styles.section}>
                  <Text style={styles.textTitle}>EL SUSCRITO PRESIDENTE DE LA FEDERACIÓN COLOMBIANA DE AJEDREZ</Text>
                  <Text style={styles.textSudTitle}>CERTIFICA QUE:</Text>
                  {participant && <Text style={styles.textOne}>{toTitleCase(participant?.full_name)}, con número de cédula {participant?.document}, perteneciente a la Liga {findLeagueById(participant?.league)}, se encuentra afiliado a la Federación Colombiana de Ajedrez.</Text>}
                  {participant && <Text style={styles.textOne}>Vigencia del presente certificado: {formatDate(participant?.date_expiration)}</Text>}
                </View>
                <View style={styles.sectionImg}>
                  <Image style={{ width: "200px" }} src="/assets/FIRMA WEYMAR.png" />
                  <Text style={styles.textTwo}>WEYMAR FERNANDO MUÑOZ MUÑOZ</Text>
                  <Text style={styles.textThree}>PRESIDENTE FECODAZ</Text>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </ContForm>
      </Info>
    </ContInfo>
  );
};

export default PdfParticipants;
