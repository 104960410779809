import { ReactNode, createContext, useState } from "react";
import { IModalsContext } from "../interface/modals.interface";

const ModalsContext = createContext<IModalsContext | null >(null);

const { Provider, Consumer: ModalsConsumer } = ModalsContext;

const ModalsProvider = ({ children }: { children: ReactNode}) => {
    const [type, setType] = useState<string>('black')
    const [viewModal, setViewModal] = useState<boolean>(false)
    const [textInit, setTextInit] = useState<string>("Lo sentimos, Documento o ID FIDE no encontrado");
    const [textBtn, setTextBtn] = useState<string>("Registrarse");
  
    return(
        <Provider value={{
            type,
            setType,
            viewModal,
            setViewModal,
            textInit,
            setTextInit,
            textBtn,
            setTextBtn
        }}>
            {children}
        </Provider>
    )
}

export { ModalsContext, ModalsProvider, ModalsConsumer }