import { useContext, useEffect, useState } from "react";
import {
  Button,
  ContForm,
  ContInfo,
  FormGroup,
  Info,
  Input,
  Text,
  Title,
} from "../../components/styled/styled.recurent";
import { SearchParticipants } from "../../services/participants.services";
import { FlowContext } from "../../contexts/flow.context";
import { IFlowContext } from "../../interface/flow.interface";
import { ISearchParticipants, SelectOptionType } from "../../interface/serviceParticipants.interface";
import { ParticipantsContext } from "../../contexts/participants.context";
import { IParticipantContext } from "../../interface/participants.interface";
import Select, { SingleValue, ActionMeta, Theme } from "react-select";
import { getTournaments, registerTournament } from "../../services/tournements.services";
import { ITournament, IgetTournaments, IregisterTournament } from "../../interface/serviceTournaments.inerface";
import { IModalsContext } from "../../interface/modals.interface";
import { ModalsContext } from "../../contexts/modals.context";
import { ILoadingContext } from "../../interface/loading.interface";
import { LoadingContext } from "../../contexts/loading.context";

const FormSearch = () => {
  const { setLoading } = useContext(LoadingContext) as ILoadingContext
  const { setParticipant } = useContext(ParticipantsContext) as IParticipantContext
  const { setViewModal,setTextInit, setTextBtn, setType } = useContext(ModalsContext) as IModalsContext
  const { flowActive, setFlowActive } = useContext(FlowContext) as IFlowContext
  const [document, setDocument] = useState<string>('')
  const [tournament, setTournament] = useState<SelectOptionType | null>(null)
  const [tournamentOptions, setTournamentOptions] = useState<SelectOptionType[]>([])

  const Search = (): void => {
    setLoading(true)
    SearchParticipants(document).then((res: ISearchParticipants) => {
      const dateEspiration = new Date(res.participants[0]?.date_expiration || '')
      const dateToday = new Date()
      setLoading(false)
      if (
        res.status === 200 && res.dataUpdate &&
        res.participants[0].payment === 'true' &&
        dateEspiration >= dateToday && tournament?.value) {
        setParticipant(res.participants[0])
        // call service regirter to tournament
        registerTournament(res.participants[0].id, tournament?.value).then((res: IregisterTournament) => {
          if (res.status === 200) {
            setFlowActive({ ...flowActive, passed: 1 })
          }
          if (res.status === 404) {
            setViewModal(true)
            setTextBtn('Aceptar')
            setTextInit('Ups, no se pudo realizar la inscripción')
            setType('black')
          }
          if (res.status === 409) {
            setViewModal(true)
            setTextBtn('Aceptar')
            setTextInit('No te puedes inscribir en este torneo mas de una vez')
            setType('black')
          }
        })
      } else {
        // view modal error data not update
        setViewModal(true)
        setTextBtn('Aceptar')
        setTextInit('Error, no se pudo realizar la inscripción por que tu licencia no esta vigente')
        setType('black')
      }
    })
  }

  const changeTournament = (
    value: SingleValue<SelectOptionType>,
    actionMeta: ActionMeta<SelectOptionType>): void => {
    setTournament(value)
  }

  const loadTournaments = (): void => {
    getTournaments().then((res: IgetTournaments) => {
      setTournamentOptions(res.tournaments.map((item: ITournament): SelectOptionType => ({ value: item.id.toString(), label: item.name })))
    })
  }

  useEffect(() => {
    loadTournaments()
  }, []);

  return (
    <ContInfo>
      <Info>
        <ContForm>
          <Title>Inscrición a torneos</Title>
          <Text>
            A continuación podrá inscribir su partición a los torneos de la Federación Colombiana de Ajedrez. Por favor digite su <b>ID FIDE</b> o número de 
            <b>DOCUMENTO</b> sin espacios ni puntos y seleccione el torneo que desea inscribir.
          </Text>
          <FormGroup className="xs-vertical">
            <Input className="boxInit box-xs col-2 not-col-xs-2 margin-xs" type="text" placeholder="ID FIDE" value={document} onChange={(e) => setDocument(e.target.value)} />
            <Select
              className="space-select-two"
              theme={(theme: Theme): Theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "var(--color-primary)",
                  primary: "var(--color-secondary)",
                  neutral0: "var(--color-secondary)",
                  neutral20: "var(--color-primary)",
                  neutral30: "var(--color-primary)",
                  neutral40: "var(--color-primary)",
                },
              })}
              value={tournament}
              onChange={changeTournament}
              options={tournamentOptions}

            />
            <Button className="boxEnd not-box-xs" onClick={Search} disabled={document === '' || tournament === null}>Inscribir</Button>
          </FormGroup>
        </ContForm>
      </Info>
    </ContInfo>
  );
};

export default FormSearch;
