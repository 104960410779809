import styled from 'styled-components';

export const BackgroundImg = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: url('/assets/55.png') no-repeat center center;
    background-size: cover;
    background-color: var(--color-background);

    & div {
        width: 100%;
        height: 100%;
        background-color: var(--color-white);
        opacity: 0.7;
    }
`;
